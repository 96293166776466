"use client";
import * as m from "@/../paraglide/messages";

import { Button, Card, CardContent, Typography } from "@mui/material";

import { Inter } from "next/font/google";
import Image from "next/image";
import { usePathname, useRouter } from "@/lib/i18n";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const router = useRouter();
  return (
    <main className="flex min-h-screen flex-col items-center justify-between p-24 bg-neutral-200 dark:bg-[#15242b]">
      <Card sx={{ width: 400, height: 500 }} className="bg-[#0D1E26]">
        <CardContent className="flex flex-col justify-start items-center gap-5 h-full">
          <Image
            src={"/wase-logo-typo-100.svg"}
            priority
            alt="Wase logo"
            width={0}
            height={0}
            className="w-[200px] h-auto invert"
          />
          <Typography>{m.pagina_nao_encontrada()}</Typography>
          <Button
            id="not-found-page-return-button"
            variant="contained"
            onClick={() => router.replace("/")}
          >
            {m.retornar_ao_inicio()}
          </Button>
        </CardContent>
      </Card>
    </main>
  );
}
